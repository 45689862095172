<div class="orders" *ngIf="orders">
    <h1 class="heading" *ngIf="!embedded" i18n>Orders</h1>
    <form (ngSubmit)="search()" *ngIf="!disableFilterUI">
        <div class="row  mt-1  mb-4">

            <label class="col-12  col-lg-2">
                <span i18n>Start Dato</span>:<br/>
                <input type="text" class="form-control" name="datestart" i18n-placeholder placeholder="Select date"
                       (click)="dateStart.toggle()" [(ngModel)]="searchDateStart" ngbDatepicker
                       [ngModelOptions]="{standalone: true}"
                       #dateStart="ngbDatepicker">
            </label>

            <label class="col-12  col-lg-2">
                <span i18n>Slut Dato</span>:<br/>
                <input type="text" class="form-control" name="dateend" i18n-placeholder placeholder="Select date"
                       (click)="dateEnd.toggle()" [(ngModel)]="searchDateEnd" ngbDatepicker
                       [ngModelOptions]="{standalone: true}"
                       #dateEnd="ngbDatepicker">
            </label>

            <label class="col-12  col-lg-2" *ngIf="advancedFilter">
                <span i18n>Order type</span>:<br/>
                <select class="form-control" [(ngModel)]="orderType"
                        [ngModelOptions]="{standalone: true}">
                    <option [ngValue]="null" i18n>All</option>
                    <ng-template ngFor let-orderType [ngForOf]="orderTypes">
                        <option [ngValue]="orderType.id">{{ orderType.name }}</option>
                    </ng-template>
                </select>
            </label>

            <label class="col-12  col-lg-2" *ngIf="advancedFilter">
                <span i18n>Order status</span>:<br/>
                <select class="form-control" [(ngModel)]="orderState"
                        [ngModelOptions]="{standalone: true}">
                    <option [ngValue]="null" i18n>All</option>
                    <ng-template ngFor let-orderState [ngForOf]="orderStates">
                        <option [ngValue]="orderState.id">{{ orderState.name }}</option>
                    </ng-template>
                </select>
            </label>

            <label class="col-12  col-lg-2" *ngIf="advancedFilter">
                <span i18n>Dealer</span>:<br/>
                <select class="form-control" [(ngModel)]="debtorFilter"
                        [ngModelOptions]="{standalone: true}">
                    <option [ngValue]="null" i18n>All</option>
                    <ng-template ngFor let-debtor [ngForOf]="debtors">
                        <option [ngValue]="debtor.id">{{ debtor.name }}</option>
                    </ng-template>
                </select>
            </label>

            <label class="col-12  col-lg-5">
                <span i18n>Search</span>:<br/>
                <div class="d-flex">
                    <div class="input-group">
                        <input type="text" class="form-control" i18n-placeholder placeholder="Search" [(ngModel)]="searchText"
                               [ngModelOptions]="{standalone: true}">
                        <div class="input-group-append">
                            <select class="appended-select  custom-select" [(ngModel)]="searchSelect" [ngModelOptions]="{standalone: true}">
                                <option [ngValue]="'order_reference'" i18n>TMT</option>
                                <option [ngValue]="'product_name'" i18n>Machine</option>
                                <option [ngValue]="'debtor_name'" i18n>Dealer</option>
<!--                            <option [ngValue]="'product_reference'" i18n>Product number</option>-->
<!--                            <option [ngValue]="'debtor_reference'" i18n>Dealer number</option>-->
                            </select>
                        </div>

                    </div>
                    <button (click)="page = 1; load(page); pushState()" type="submit" class="btn  btn-primary  ml-2" i18n>Search</button>
                </div>
            </label>
        </div>
    </form>

    <div class="row  mt-1  mb-4" *ngIf="selectedOrders.length > 0">
        <label class="col-12">
            <p class="mb-0"><span i18n>Set status</span>:</p>
            <ng-container *ngFor="let orderState of orderStates">
                <button (click)="setState(orderState)" class="btn btn-sm  mt-3  mr-1 btn-primary ">{{orderState.name}}</button>
            </ng-container>
        </label>
    </div>

    <app-table [autoSizeFirstColumn]="true" [autoSizeLastColumn]="true">
        <thead>
        <tr>
            <th></th>
            <th>
                <a i18n class="table-header-link" (click)="load(page, 'sort-by-machine-field', 'name', '', true, idValuesService.productMachineCategoryId)" i18n>Machine</a>
                <fa-icon *ngIf="filter.sortValue !==  'name'" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'name' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'name' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th *ngIf="!showRekvNr">
                <a class="table-header-link" (click)="load(page,'sort-by-reference', idValuesService.tmtNumberId, 'numeric', true)" i18n>TMT</a>
                <fa-icon *ngIf="filter.sortValue !== idValuesService.tmtNumberId" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.tmtNumberId && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.tmtNumberId && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th *ngIf="showRekvNr" i18n>Req. no.</th>
            <th>
                <a class="table-header-link" (click)="load(page,'sort-by-field', 'state', null, true)" i18n>Order type</a>
                <fa-icon *ngIf="filter.sortValue !== 'state'" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'state' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'state' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>

            <th>
                <a class="table-header-link" (click)="load(page,'sort-by-field', 'state', null, true)" i18n>Order status</a>
                <fa-icon *ngIf="filter.sortValue !== 'state'" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'state' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'state' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
<!--            <th>-->
<!--                <a class="table-header-link" (click)="load(page,'sort-by-field', 'branch', null, true)" i18n>Branch</a>-->
<!--                <fa-icon *ngIf="filter.sortValue !== 'branch'" class="ml-1" [icon]="faSort"></fa-icon>-->
<!--                <fa-icon *ngIf="filter.sortValue === 'branch' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>-->
<!--                <fa-icon *ngIf="filter.sortValue === 'branch' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>-->
<!--            </th>-->
            <th>
                <a class="table-header-link" (click)="load(page,'sort-by-field', 'debtor', null, true)" i18n>Dealer</a>
                <fa-icon *ngIf="filter.sortValue !== 'debtor'" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'debtor' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'debtor' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th>
                <a class="table-header-link" (click)="load(page, 'sort-by-date', idValuesService.productionDateId, null, true)" i18n>Production</a>
                <fa-icon *ngIf="filter.sortValue !== idValuesService.productionDateId" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.productionDateId && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.productionDateId && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th *ngIf="!limitInfo">
                <a class="table-header-link" (click)="load(page, 'sort-by-field','total', null, true)" i18n>Total price in DKK</a>
                <fa-icon *ngIf="filter.sortValue !== 'total'" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'total' && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === 'total' && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th>
                <a class="table-header-link" (click)="load(page, 'sort-by-date', idValuesService.orderedDateId, null, true)">
                <ng-container *ngIf="showRekvNr"><span i18n>Created</span></ng-container>
                <ng-container *ngIf="!showRekvNr"><span i18n>Ordered</span></ng-container>
                </a>
                <fa-icon *ngIf="filter.sortValue !== idValuesService.orderedDateId" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.orderedDateId && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.orderedDateId && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th>
                <a class="table-header-link" (click)="load(page, 'sort-by-date', idValuesService.homeDateId, null, true)" i18n>Arrived</a>
                <fa-icon *ngIf="filter.sortValue !== idValuesService.homeDateId" class="ml-1" [icon]="faSort"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.homeDateId && filter.direction==='DESC'" class="ml-1" [icon]="faSortDown"></fa-icon>
                <fa-icon *ngIf="filter.sortValue === idValuesService.homeDateId && filter.direction==='ASC'" class="ml-1" [icon]="faSortUp"></fa-icon>
            </th>
            <th></th>
        </tr>
        </thead>

        <tbody>
        <ng-template ngFor let-order [ngForOf]="orders">
            <tr>
                <td>
                    <input type="checkbox" (click)="onCheckboxChange(order)" [checked]="orderIsSelected(order)"
                           *ngIf="order.order_state_id !== idValuesService.offerOrderStateId && authService.hasPermission('admin')">
                </td>
                <td>{{ machinesService.getMachineOrderLine(order)?.product.name }}<span *ngIf="order.isFrozen()" class="badge  badge-danger" i18n>Frozen</span></td>
                <td *ngIf="!showRekvNr">{{ order.getReference(idValuesService.tmtNumberId) }}</td>
                <td *ngIf="showRekvNr">{{order?.getFieldValueForId(idValuesService.requisitionFieldValueId)}}
                </td>
                <td>{{order?.order_type?.name}}</td>
                <td>
                    {{ order.order_state.name }}


                    <span *ngIf="order?.getFieldValueForId(idValuesService.hiddenFieldValueId) == 1" class="badge  badge-secondary  mr-2">Skjult</span>

                    <span *ngIf="order.isFrozen()" class="badge  badge-danger" i18n>Frozen</span>
                </td>
<!--                <td>{{ order.branch.name }}</td>-->
                <td>
                    <span *ngIf="order.debtor">{{ order.debtor.name }}</span>
                    <span *ngIf="!order.debtor" class="text-muted" i18n>-</span>
                </td>
                <td>
                    <ng-container *ngIf="order.getDate('is_production')">
                        Uge {{ order.getDate('is_production')._joinData.time.isoWeek()|json }}
                        {{ order.getDate('is_production')._joinData.time.isoWeekYear()|json }}
                    </ng-container>
                </td>
                <td *ngIf="!limitInfo">{{ order.getTotalUnadjusted() | currency:'DKK':'':null }}</td>
                <td>
                    <ng-container *ngIf="order.getDate('is_creation')">
                        {{ order.getDate('is_creation')._joinData.time.format('YYYY-MM-DD')}}
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="order.getDateById(idValuesService.homeDateId)">
                        {{ order.getDateById(idValuesService.homeDateId)._joinData.time.format('YYYY-MM-DD')}}
                    </ng-container>
                </td>
                <td>
                    <!-- @todo move into a reservation component -->
                    <ng-container *ngIf="!disableEdit">

                    <ng-container *ngIf="order.reservations && order.reservations.length>0">
                        <ng-container *ngFor="let reservation of order.reservations">
                            <span i18n>Reserved for</span> {{reservation.end.format('YYYY-MM-DD hh:mm')}}
                            <fa-icon *ngIf="reservation.debtor" class="mr-3" [icon]="faInfoCircle" [ngbTooltip]="reservation.debtor.name" [triggers]="'hover focus'"></fa-icon>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!order.reservations || order.reservations.length ===0">
                        <a href="javascript:0"  (click)="startReserveration(order)" *ngIf="order.order_state_id === idValuesService.pipelineOrderStateId || order.order_state_id === idValuesService.inventoryOrderStateId" i18n>Reserve</a>
                    </ng-container>

                    <a [routerLink]="['/', 'offers', {outlets: {offer_outlet: ['load',order.id]}}]" *ngIf="order.order_state_id === idValuesService.offerOrderStateId" i18n>Resume</a>
                    <a [routerLink]="['/', 'custom','print-friendly-order',order.id]" *ngIf="order.order_state_id === idValuesService.offerOrderStateId" i18n>PDF</a>
                    <a href="javascript:0" (click)="deleteOrder(order)"  *ngIf="order.order_state_id === idValuesService.offerOrderStateId" i18n>Delete offer</a>

                    <a routerLink="/orders/{{ order.id }}" *ngIf="order.order_state_id !== idValuesService.offerOrderStateId">{{ order.isFrozen() ? 'View' : 'Edit' }}</a>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </app-table>

    <ng-container *ngIf="count > orders.length">
        <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="perPage" [maxSize]="10"
                        [rotate]="true" [collectionSize]="count" (pageChange)="pageChange($event)" [size]="'sm'"
                        [disabled]="loading"></ngb-pagination>
    </ng-container>
</div>

<app-loader *ngIf="!orders || loading" [useOverlayLoader]="!!(loading && orders)" i18n>Loading...</app-loader>
